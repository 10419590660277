import React, { useEffect, useState } from "react";
import { selfHelpService } from "../_services";
import { ArbitraryType } from "../_models/types";
import useAPI from "../_hooks/useAPI";
import { BsChevronRight } from "react-icons/bs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { sleep } from "../_helpers/sleep";

export const SelfHelpArticle: React.FC = () => {
    const api = useAPI()
    const navigate = useNavigate()
    const { state } = useLocation()
    const params = useParams<string>()
    const [selfHelpArticle, setSelfHelpArticle] = useState<ArbitraryType>()
    const [isLoading, setIsLoading] = useState<boolean>(false)

    async function getData() {
        setIsLoading(true)
        await sleep(1500)
        try {
            const res = await selfHelpService.getSelfHelpArticle(params?.id || state?.id, api)
            setSelfHelpArticle(res)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <div className="col-12">
            <div className="row">
                <div className="hero-image">
                    <img src="/images/L_ABOUT_us.jpg" alt="" className="w-100 img-fluid" />
                    <div className="h-100 w-100 hero-image__title">
                        <div className="container text-center">
                            <h3>{state?.title}</h3>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="col-12 col-md-8 offset-md-2 col-lg-4 offset-lg-4 h-100">
                        {
                            isLoading
                                ?
                                <div className={`card`}>
                                    <div className="card-body">
                                        <div className={`d-flex justify-content-center align-items-center w-100`} style={{ minHeight: 100 }}>
                                            <Spinner animation="border" size="sm" variant="primary" />
                                        </div>
                                    </div>
                                </div>
                                :
                                <React.Fragment>
                                    {selfHelpArticle?.description && <div className={`card`}>
                                        <div className="card-body">
                                            <div
                                                dangerouslySetInnerHTML={{ __html: selfHelpArticle?.description }}
                                            />
                                        </div>
                                    </div>}
                                </React.Fragment>
                        }
                        <br />
                        <br />
                        <h4 className={`bold`}>Still need help?</h4>
                        <div className={`card`}>
                            <div className="card-body">
                                <ul className="list-group list-group-flush">
                                    <li
                                        onClick={() => {
                                            navigate(`/self-help/log-a-ticket`)
                                        }}
                                        className={`cursor-pointer list-group-item list-group-item-action d-flex justify-content-between align-items-center`}>
                                        Log a ticket
                                        <BsChevronRight />
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                </div>
            </div>
        </div>
    )
}